.sidebarMain{
    display: flex;
    justify-content: center;
    margin-left: auto;
    width: 262px;
    height: 1024px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2px 0px 63.20000076293945px 0px rgba(82, 79, 79, 0.25);

}
.sidebarContainer{
padding-top: 50px;
}
.sidelogo{
padding-bottom: 50px;
}

.sidelogoMain{
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    text-decoration: none;
}

.active{
    color: rgba(217, 42, 37, 1);
border-radius: 0px 10px 10px 0px;
text-decoration: none !important;
font-family: Changa;
font-size: 18px;
font-weight: 400;
line-height: 33px;
text-align: left;
}

.sideText{
    font-family: Changa;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: rgba(177, 177, 177, 1);
    text-decoration: none !important;

}





@media (max-width:1199.5px){
    .sidebarMain{
        width: 100%;
    }
    .sidebarContainer{
        padding-top: 10px;
        }
}