.accordion-button:not(.collapsed) {
    color: rgba(25, 28, 31, 1) !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.accordion-button:focus {
    border-color:  transparent !important;
    box-shadow: none !important;
}
.accordion-item:first-of-type {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
}
.accordion-item:last-of-type {
    border-bottom-right-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius:16px !important;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
    font-family: Changa;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 22px;
    letter-spacing: -0.10000000149011612px;
    text-align: right;
    color: rgba(25, 28, 31, 1);
}
.accordion-button::after {
    margin-left: 0 !important;
}
.accordion{
    --bs-accordion-btn-icon-width: 1rem !important;
}

@media (max-width:710px){
    .accordion{
        width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
