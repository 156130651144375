.offcanvas-body {
    flex-grow: 1;
    padding: 0 !important;
    overflow-y: auto;
}
.btn-primary {
    --bs-btn-color: #000000;
    --bs-btn-bg:  rgba(153, 153, 153, 0);
    --bs-btn-border-color:  rgba(153, 153, 153, 0);
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg:  rgba(153, 153, 153, 0.405);
    --bs-btn-hover-border-color:  rgba(153, 153, 153, 0.405);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg:  rgba(153, 153, 153, 0.405);
    --bs-btn-active-border-color:  rgba(153, 153, 153, 0.405);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg:  rgba(153, 153, 153, 0.405);
    --bs-btn-disabled-border-color:  rgba(153, 153, 153, 0.405);
}
@media (max-width:1199.5px){
    .btn {
        height: 30px !important;
        display: flex !important;
        --bs-btn-padding-x: 10px !important;
        --bs-btn-padding-y: 0px !important;
        --bs-btn-font-size: 1.3rem !important;
        margin-top: 40px !important;
    }
    .offcanvas-xl{
        background: rgba(255, 255, 255, 1);

    }
}

.canvusBtn{
    box-shadow: none !important;
    border: none !important;

}
.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: transparent !important;
    border-color: var(--bs-btn-hover-border-color);
}