.profileMain{
    display: flex;
    flex-direction: row-reverse;
    gap: 30px;
}
.profileContainer{
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
}
.controlMainButtonText{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;

}
.controlBtn{
    width: 86px;
    height: 30px;
    border-radius: 8px;
    background: rgba(217, 42, 37, 1);
    border: 0;
    font-family: Changa;
font-size: 9.44px;
font-weight: 600;
line-height: 17.36px;
text-align: center;
color: rgba(255, 255, 255, 1);

}
.mainText{
    font-family: Changa;
    font-size: 21.54px;
    font-weight: 600;
    line-height: 39.64px;
    text-align: left;
    color: rgba(0, 0, 0, 1);

}
.profilemainHeading2{
    padding-top: 30px;
    font-family: Changa;
    font-size: 17.09px;
    font-weight: 500;
    line-height: 31.45px;
    text-align: right;
    color: rgba(44, 44, 44, 1);

}
.profileTextMainSection{
    margin-top: 30px;
    margin-left: 30px;
    padding-bottom: 45px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2px 0px 63.20000076293945px 0px rgba(82, 79, 79, 0.15);

}
.profileTextContSection{
   width:95% ; 
   margin-left: auto;
   margin-right: auto;
}
.profileMainText{
    padding-top: 20px;
    font-family: Changa;
    font-size: 20px;
    font-weight: 600;
    line-height: 36.8px;
    text-align: right;
    color: rgba(38, 38, 38, 1);

}
.profileInfoMain{
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profilePhoneMain{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.phoneText{
    padding-top: 20px;
    font-family: Changa;
    font-size: 16px;
    font-weight: 500;
    line-height: 29.44px;
    text-align: right;
    color: rgba(107, 107, 107, 1);

}
.InputMain1{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
width: 207px;
height: 44px;
border-radius: 10px;
border: 1px solid rgba(209, 209, 209, 1);
}
.InputMain2{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
width: 249px;
height: 44px;
border-radius: 10px;
border: 1px solid rgba(209, 209, 209, 1);
}
.InputMain3{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 138px;
height: 44px;
border-radius: 10px;
border: 1px solid rgba(209, 209, 209, 1);
}
.phoneInput1{
    width: 145px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    padding: 0px !important;
    border: 0 !important;
    outline: none !important;
}
.phoneInput2{
    width: 195px;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: left;
color: rgba(164, 164, 164, 1);
padding: 0px !important;
border: 0 !important;
outline: none !important;
}
.phoneInput3{
    width: 70px;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: left;
color: rgba(164, 164, 164, 1);
padding: 0px !important;
border: 0 !important;
outline: none !important;
    
}
.infoHeading{
    padding-top: 70px;
    font-family: Changa;
font-size: 20px;
font-weight: 600;
line-height: 36.8px;
text-align: right;
color: rgba(38, 38, 38, 1);

}
.infomainDiv{
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.infoText{
    padding-top: 20px;
    font-family: Changa;
font-size: 16px;
font-weight: 500;
line-height: 29.44px;
text-align: right;
color: rgba(164, 164, 164, 1);

}
.infoDiv{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

}

.infoText2{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: center;
color: rgba(164, 164, 164, 1);
width: 66px;
height: 44px;
border-radius: 10px;
border: 1px solid rgba(209, 209, 209, 1);

}
.infoText3{
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: right;
    color: rgba(164, 164, 164, 1);
    width: 100%;
    height: 44px;
    border-radius: 10px;
    border: 1px solid rgba(209, 209, 209, 1);
}
.infoText4{
    background: rgba(47, 184, 110, 1);

    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: center;
color:  rgba(255, 255, 255, 1);
width: 66px;
height: 44px;
border-radius: 10px;
}
.infoText5{
    background: rgba(4, 14, 65, 1);
    width: 98px;
    height: 44px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: right;
color:  rgba(255, 255, 255, 1);
border-radius: 10px;
}

.infomainDiv2{
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.infomainDiv3{
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.infoDiv2{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.infoButtonMain{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

}
.infoBtn{
    width: 114px;
    height: 35px;
    border-radius: 10px;
    background: rgba(217, 42, 37, 1);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
    border: 0;
    color: rgba(255, 255, 255, 1);

}
.infoBtn2{
    width: 156px;
    height: 35px;
    border-radius: 10px;
    background: rgba(4, 14, 65, 1);
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: center;
color: rgba(255, 255, 255, 1);
border: 0;

}
.infoHeading2{
    padding-top: 30px;
    font-family: Changa;
font-size: 20px;
font-weight: 600;
line-height: 36.8px;
text-align: right;
color: rgba(38, 38, 38, 1);

}
.infoSwitch{
    padding-top: 15px;
}

.infoText6{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: right;
color: rgba(164, 164, 164, 1);

}

@media (max-width:992px){
    .profileInfoMain {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
    }
    .infomainDiv {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
    }
    .infomainDiv2 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
    }
    .infomainDiv3 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
    }
    .infoButtonMain {
        padding-top: 30px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
    }
    .infoDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

@media (max-width:500px){
    .profileMainText {
        padding-top: 20px;
        font-size: 17px;
    }
    .phoneText {
        padding-top: 20px;
        font-size: 14px;
    }
    .phoneInput1{
        font-size: 14px;
    }
    .phoneInput2 {
        font-size: 14px;
        width: 175px;
    }
    .profileMain{
        gap: 0px;
    }
    .profileTextMainSection{
        margin-top: 30px;
        margin-left:10px;
    }
    .InputMain2 {
        margin-top: 15px;
        width: 230px;
    }
    .phoneInput3 {
        font-size: 14px;
    }
    .infoHeading{
        padding-top: 40px;
        font-size: 17px;
    }
    .infoText {
        padding-top: 20px;
        font-size: 14px;
    }
    .infoText2{
        margin-top: 10px;
        font-size: 14px;
        width: 54px;
        height: 40px;
    }
    .infoText3 {
        margin-top: 10px;
        font-size: 14px;
        height: 40px;
    }
    .infoText4 {
        margin-top: 10px;
        font-size: 14px;
        width: 62px;
        height: 42px;
    }
    .infoText5 {
        width: 80px;
        height: 38px;
        margin-top: 10px;
        font-size: 14px;
    }
    .infoBtn {
        width: 100px;
        height: 32px;
        font-size: 14px;
    }
    .infoBtn2 {
        width: 140px;
        height: 32px;
        font-size: 14px;
    }
    .infoHeading2 {
        padding-top: 40px;
        font-size: 17px;
    }
    .infoText6 {
        font-size: 14px;
    }
}