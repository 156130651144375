.shipmentMain {
  display: flex;
  flex-direction: row-reverse;
  gap: 30px;
}
.shipmentContainer {
  width: calc(100% - 292px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-left: 25px;
}
.controlMainButtonText {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}
.controlBtn {
  width: 86px;
  height: 30px;
  border-radius: 8px;
  background: rgba(217, 42, 37, 1);
  border: 0;
  font-family: Changa;
  font-size: 9.44px;
  font-weight: 600;
  line-height: 17.36px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.mainText {
  font-family: Changa;
  font-size: 21.54px;
  font-weight: 600;
  line-height: 39.64px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.shipmentButtondivMain {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.shipmentBtn {
  width: 81px;
  height: 34px;
  border-radius: 8px;
  background: rgba(217, 42, 37, 1);
  font-family: Changa;
  font-size: 9.44px;
  font-weight: 600;
  line-height: 17.36px;
  text-align: center;
  border: 0;
  color: rgba(255, 255, 255, 1);
}
.btnDivText {
  font-family: Changa;
  font-size: 17.09px;
  font-weight: 500;
  line-height: 31.45px;
  text-align: left;
  color: rgba(44, 44, 44, 1);
}
.tableButtonMain {
  margin-top: 40px;
  /* height: 820px; */
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: -2px 0px 63.20000076293945px 0px rgba(82, 79, 79, 0.15);
}
.tableBtnMain {
  padding-top: 20px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;

  gap: 18px;
}
.leftBtnMain {
  display: flex;
  gap: 8px;
  align-items: center;
}
.serchIconMain {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 39px;
  border-radius: 9.55px;
  background: rgba(217, 42, 37, 1);
}
.rightBtnMain {
  display: flex;
  gap: 5px;
  align-items: center;
}
@media (max-width: 1420px) {
  .rightBtnMain {
    flex-wrap: wrap;
  }
}

.headbtn1 {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: 75.46px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(74, 74, 74, 1);
}
.headbtn2 {
  width: 75.46px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(74, 74, 74, 1);
}
.headbtn3 {
  width: 73.55px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn4 {
  width: 52.54px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn5 {
  width: 63.05px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn6 {
  width: 70.69px;
  height: 39.17px;
  border-radius: 9.55px;
  background-color: transparent;
  border: 0.96px solid rgba(217, 217, 217, 1);
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn7 {
  width: 53.49px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn8 {
  width: 75.46px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(74, 74, 74, 1);
}
.headbtn9 {
  width: 82.15px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(74, 74, 74, 1);
}
.headbtn10 {
  width: 76.42px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn11 {
  width: 97.44px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  background-color: transparent;
  font-family: Changa;
  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}
.headbtn12 {
  width: 70.69px;
  height: 39.17px;
  border-radius: 9.55px;
  border: 0.96px solid rgba(217, 217, 217, 1);
  font-family: Changa;
  background-color: transparent;

  font-size: 9.01px;
  font-weight: 400;
  line-height: 16.58px;
  text-align: center;
  color: rgba(211, 211, 211, 1);
}

@media (max-width: 1199.5px) {
  .shipmentContainer {
    width: 94%;
  }
  .shipmentMain {
    display: flex;
    flex-direction: row-reverse;
    gap: 0px;
  }
}
@media (max-width: 768px) {
  .shipmentContainer {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-left: 25px;
  }
}
@media (max-width: 425px) {
  .shipmentContainer {
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-left: 25px;
  }
}
