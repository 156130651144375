.pe-3 {
    padding-right: 10rem !important;
}
.mb-3 {
    margin-bottom: 0rem !important;
}
.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 1)!important;
}
.navbar {
    --bs-navbar-padding-x: 4rem !important;
    --bs-navbar-nav-link-padding-x: 1rem !important;
    /* --bs-navbar-padding-y: 0rem !important; */
    height:65px !important ;
}
.nav-link {
display: flex !important;
align-items: center !important;
font-family: Changa;
font-size: 16px;
font-weight: 400;
line-height: 29.44px;
text-align: left;
color: rgba(94, 93, 93, 1);

}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    display: flex !important;
    align-items: center !important;
    font-family: Changa;
    font-size: 16px;
    font-weight: 700;
    line-height: 29.44px;
    text-align: left;
    color: rgba(217, 42, 37, 1);
}

@media (max-width:991.5px){
    .justify-content-end {
        justify-content: flex-start !important;
    }

.offcanvas-body {
  display: flex !important;
  flex-direction: column-reverse !important;
}
}

@media (max-width:768px){
    .navbar {
        --bs-navbar-padding-x: 1rem !important;

    }
}
@media (max-width:425px){
    .navbar {
        --bs-navbar-padding-x: 0rem !important;
    }
}