.signupContainer{
    width: 100%;
    display: flex;
    gap: 20px;
    min-height: 100vh;
}
.signupLeftsideMain{
    width: 60%;
    position: relative;

}
.signBgImg{
    width: 766px;
    height: 1031px;
}

.signlogoImg{
    position: absolute;
    width: 216px;
height: 123px;
top: 23px;
left: 51px;

}
.signupRightsideMain{
  padding-top: 50px;
  padding-right: 70px;
    width: 40%;
}
.signHeading{
    font-family: Changa;
font-size: 32px;
font-weight: 600;
line-height: 58.88px;
text-align: right;
color: rgba(0, 0, 0, 1);

}
.signText{
    font-family: Changa;
    font-size: 18px;
    font-weight: 400;
    line-height: 33.12px;
    text-align: right;
    color: rgba(108, 113, 123, 1);

}
.signInputMain{
    text-align: right;
    padding-top: 20px;
}
.signinput{
    width: 85%;
height: 60px;
border-radius: 10px ;
border: 1px solid rgba(209, 209, 209, 1);
padding-right: 20px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: right;
color: rgba(129, 129, 129, 1);

}
.signButton{
    padding-top: 20px;
    padding-bottom: 30px;
text-align: right;
}
.signBtn{
    width: 85%;
height: 60px;
border-radius: 10px;
background: rgba(217, 42, 37, 1);
box-shadow: 0px 15px 30px -10px rgba(119, 84, 246, 0.27);
border: 0;
font-family: Changa;
font-size: 16px;
font-weight: 700;
line-height: 29.44px;
text-align: center;
color: rgba(255, 255, 255, 1);

}
.signLastText{
    font-family: Changa;
font-size: 16px;
font-weight: 400;
line-height: 29.44px;
text-align: right;
color: rgba(129, 129, 129, 1);

}



@media (max-width:1339px){
    .signBgImg{
        width: 100%;
        /* height: 100vh; */
    }
}
@media (max-width:1200px){
    .signupRightsideMain{
        padding-top: 40px;
        padding-right: 40px;
        width: 40%;
    }
    .signBgImg{
        width: 100%;
        height: 100%;
    }
    .signlogoImg{
        width: 180px;
        height: 105px;
    }
}
@media (max-width:1024px){
    .signBgImg{
        width: 100%;
        height: 100%;

    }
}
@media (max-width:768px){
    .signBgImg{
        width: 100%;
        height: 100%;

    }
    .signupContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .signupLeftsideMain{
        width: 100%;
        position: relative;
    }
    .signupRightsideMain {
        padding-top: 40px;
        padding-right: 40px;
        padding-bottom: 20px;

        width: 100%;
    }
    
    
}
@media (max-width:540px){

    .signBgImg{
        width: 100%;
        height: 100%s;
    }
    .signlogoImg{
        width: 150px;
        height: 90px;
        top: 22px;
        left: 24px;
    }
}
@media (max-width:425px){

    .signBgImg{
        width: 100%;
        height: 100%;
    }
    .signlogoImg{
        width: 150px;
        height: 90px;
        top: 22px;
        left: 24px;
    }
    .signupRightsideMain {
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        width: 100%;
    }
    .signText {
        font-size: 17px;
        
    line-height: 28px;
    }
    .signLastText{
        font-size: 14px;
    }
    .signinput {
        width: 100%;
        height: 50px;
    }
    .signBtn{
        width: 100%;
        height: 50px;
    }
}