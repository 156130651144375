.controlMain{
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
}
.controlContainer{
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
}
.controlMainButtonText{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;

}
.controlBtn{
    width: 86px;
    height: 30px;
    border-radius: 8px;
    background: rgba(217, 42, 37, 1);
    border: 0;
    font-family: Changa;
font-size: 9.44px;
font-weight: 600;
line-height: 17.36px;
text-align: center;
color: rgba(255, 255, 255, 1);

}
.mainText{
    font-family: Changa;
    font-size: 21.54px;
    font-weight: 600;
    line-height: 39.64px;
    text-align: left;
    color: rgba(0, 0, 0, 1);

}
.control2mainbuttonText{
    display: flex;
    align-items: center;
  margin-top: 25px;
  margin-left: 40px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2px 0px 63.20000076293945px 0px rgba(82, 79, 79, 0.15);
    border-radius: 10px;
    height: 59px;
}
.control2ContainerbuttonText{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.controlBtn2{
    width: 81px;
    height: 34px;
    border-radius: 8px;
    border: 0;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-family: Changa;
font-size: 9.44px;
font-weight: 600;
line-height: 17.36px;
text-align: center;
color: rgba(255, 255, 255, 1);
background: rgba(217, 42, 37, 1);
}
.control2Text{
    padding-right: 20px;
    font-family: Changa;
font-size: 14px;
font-weight: 400;
line-height: 25.76px;
text-align: right;
color: rgba(134, 134, 134, 1);
}

.cardMain{
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    padding-top: 50px;
    flex-wrap: wrap;

}
.card1Main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 254px;
height: 209px;
border-radius: 10px;
background: rgba(255, 255, 255, 1);
box-shadow: -2px 0px 63.20000076293945px 0px rgba(82, 79, 79, 0.15);
cursor: pointer;
}
.cardTextMain{
    display: flex;
    align-items: center;
    gap: 10px;

}
.cardtext1{
    font-family: Changa;
font-size: 20.18px;
font-weight: 400;
line-height: 37.14px;
text-align: left;
color: rgba(4, 14, 65, 1);

}
.cardtext2{
    font-family: Changa;
    font-size: 40.71px;
    font-weight: 600;
    /* line-height: 74.91px; */
    text-align: left;
    color: rgba(4, 14, 65, 1);

}
.TextIconDiv{
    border-top: 1px solid rgba(230, 230, 230, 1);
    width: 254px;
height: 209px;
border-radius: 10px;
background: rgba(255, 255, 255, 1);
box-shadow: -0px 0px 63.20000076293945px 0px rgba(82, 79, 79, 0.15);

}
.textIconMain{
    margin-top: 10px;
    width: 88%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

}
.TextIcon{
    font-family: Changa;
    font-size: 20.18px;
    font-weight: 400;
    line-height: 37.14px;
    text-align: left;
    color: rgba(153, 153, 153, 1);

}

@media (max-width:425px){
    .card1Main {
        width: 86%;
    }
}