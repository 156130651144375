.tableMain{
    padding-top: 20px;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}
.tablemainhead{
    border-bottom: 1px solid rgba(208, 208, 208, 1) !important;

}
th{
    font-family: Changa;
    font-size: 11.01px;
    font-weight: 500;
    line-height: 20.26px;
    text-align: right;
    color: rgba(217, 42, 37, 1) !important;

}
.table{
  
    min-width: 1000px;
}

.table>:not(caption)>*>* {
    padding-bottom: 0rem  !important;
    color: none!important;
    background-color: transparent !important;
    border-bottom-width: 0 !important;
    box-shadow: none !important;
}
.btntext{
    width: 53px;
height: 26px;
border-radius: 8px;
    border: 1px solid rgba(217, 42, 37, 1);
    font-family: Changa;
font-size: 9.44px;
font-weight: 600;
line-height: 17.36px;
text-align: center;
color: rgba(217, 42, 37, 1);
background-color: transparent;

}
.Text2{
    padding-top: 13px !important;
    font-family: Changa;
    font-size: 11.01px;
    font-weight: 400;
    line-height: 20.26px;
    text-align: center;
    color: rgba(94, 94, 94, 1);
}
.btntext2{
    width: 64px;
height: 25px;
border-radius: 8px;
background: rgba(47, 184, 110, 1);
font-family: Changa;
font-size: 9.44px;
font-weight: 600;
line-height: 17.36px;
text-align: center;
border: 0;
color: rgba(255, 255, 255, 1);
}
.Text3{
    padding-top: 13px !important;

    font-family: Changa;
    font-size: 11.01px;
    font-weight: 400;
    line-height: 20.26px;
    text-align: center;
    color: rgba(94, 94, 94, 1);

}
.Text4{
    padding-top: 13px !important;

    font-family: Changa;
    font-size: 13px;
    font-weight: 400;
    line-height: 23.92px;
    text-align: center;
    color: rgba(94, 94, 94, 1);

    
}
.Text5{
    font-family: Changa;
    font-size: 11.01px;
    font-weight: 400;
    line-height: 20.26px;
    text-align: center;
    color: rgba(47, 184, 110, 1) !important;

}
.tbbtn3{
    width: 52px;
height: 25px;

border-radius: 8px;
border: 0;
    background: rgba(47, 184, 110, 1) !important;
    font-family: Changa;
    font-size: 9.44px;
    font-weight: 600;
    line-height: 17.36px;
    text-align: center;
    color: rgba(255, 255, 255, 1) !important;

}
.Text6{
    font-family: Changa;
    font-size: 13px;
    font-weight: 400;
    line-height: 23.92px;
    text-align: right;
    color: rgba(94, 94, 94, 1);

}
td{
    text-align: center;
}
