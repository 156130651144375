.privacyContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;

}
.privacyHeading{
    font-family: Almarai;
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
    text-align: right;
    color: rgba(217, 42, 37, 1);
}
.privacyText{
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Almarai;
    font-size:18px;
    font-weight: 400;
    line-height: 28px;
    text-align: right;
    color: rgba(108, 117, 125, 1);

}

@media (max-width:768px){
    .privacyContainer{
        width: 94%;
        padding-top: 40px;
        padding-bottom: 30px;
    
    } 
}
@media (max-width:500px){
    .privacyHeading{
        font-size: 23px;
        line-height: 26px;
    }
    .privacyText{
        padding-top: 20px;
        padding-bottom: 20px;
        font-size:16px;
        line-height: 25px;
    
    }
}