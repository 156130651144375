.homeContainerMain{
background-image: url("../../Images/bghome.png");
background-repeat: no-repeat;
background-size: cover;
    background-position: center;

}
.homeContainer{
  
    width: 87%;
    margin-left: auto;
    margin-right: auto;
}
.homeHeading{
    padding-top: 230px;
    font-family: Changa;
    font-size: 46.78px;
    font-weight: 700;
    line-height: 86.08px;
    text-align: right;
    color: rgba(217, 42, 37, 1);

}
.homeText{
    font-family: Changa;
    font-size: 20px;
    font-weight: 400;
    line-height: 36.8px;
    text-align: right;
    color: rgba(255, 255, 255, 1);

}
.homeButton{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 45px;
}
.homeBtn{
    width: 143.24px;
    height: 43.83px;
    border-radius: 9.62px;
    border: 0;
    background: rgba(4, 14, 65, 1);
    font-family: Changa;
font-size: 17.1px;
font-weight: 700;
line-height: 31.47px;
text-align: center;
color: rgba(255, 255, 255, 1);


}
.homeButton2Main{
    padding-top: 180px;
    padding-bottom: 40px;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;

}
.homeBtn1{
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 223px;
height: 89px;
border-radius: 10px;
border: 0;
background: rgba(255, 255, 255, 1);
box-shadow: -2px 2px 14.300000190734863px 0px rgba(66, 66, 66, 0.2);

font-family: Changa;
font-size: 16px;
font-weight: 400;
line-height: 29.44px;
text-align: right;
color: rgba(70, 70, 70, 1);

}
.btnTextInner{
width: 127px;
}
.btnTextInner2{
    width: 104px;

}
.SecondContainerMain{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-top: 70px;
    width: 87%;
    margin-left: auto;
    margin-right: auto;
}
.secondLeftHeading{
    font-family: Changa;
font-size: 32px;
font-weight: 600;
line-height: 58.88px;
text-align: right;
color: rgba(0, 0, 0, 1);
}
.secondLeftText{
    width: 614px;
    font-family: Changa;
    font-size: 18px;
    font-weight: 400;
    line-height: 33.12px;
    text-align: right;
    color: rgba(108, 113, 123, 1);

}
.secondLeftButton{
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.secondrightImg{
    width: 571px;
    height: 496.73px;
}
.secondleftsideBtn{
    width: 278px;
height: 44px;
border-radius: 9.62px ;
background: rgba(217, 42, 37, 1);
font-family: Changa;
font-size: 17.1px;
font-weight: 700;
line-height: 31.47px;
text-align: center;
color: rgba(255, 255, 255, 1);
border: 0;
}
.midSectionMain{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.midSectionHeading{
    font-family: Changa;
font-size: 32px;
font-weight: 600;
line-height: 58.88px;
text-align: left;
color: rgba(0, 0, 0, 1);

}
.midSectionText{
    padding-top: 8px;
    width: 77%;
    font-family: Changa;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: rgba(108, 113, 123, 1);

}
.serviceMain{
    margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(217, 42, 37, 1);
}
.serviceHeading{
    padding-top: 30px;
    font-family: Changa;
    font-size: 32px;
    font-weight: 600;
    line-height: 58.88px;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}
.serviceIconsTextMain{
    display: flex;
    align-items: center;
    gap: 120px;
    justify-content: space-around;

}
.iconMain{
    padding-bottom: 30px;

    padding-top: 35px;
    display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.IconDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 113px;
height: 113px;
border-radius: 100px;
    background: rgba(255, 255, 255, 1);

}
.serIcon1{
    width: 61px;
    height: 61px;
}
.iconText{
    padding-top: 10px;
    font-family: Changa;
font-size: 20px;
font-weight: 600;
line-height: 36.8px;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.deliveryMain{
    background-image: url("../../Images/delbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 568px;
}
.deliveryContainer{
    padding-top: 190px;
    width: 41%;
}
.delivery1Heading{
    font-family: Changa;
font-size: 36px;
font-weight: 700;
line-height: 40px;
letter-spacing: -0.20000000298023224px;
text-align: right;
color: rgba(255, 255, 255, 1);

}
.delivery1Text{
    font-family: Changa;
font-size: 18px;
font-weight: 400;
line-height: 26px;
text-align: right;
color: rgba(255, 255, 255, 1);

}
.delivery2HeadingMain{
    padding-top: 30px;
    gap: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
.delivery2Heading{
    font-family: Changa;
font-size: 127.61px;
font-weight: 700;
line-height: 91.15px;
letter-spacing: -0.4557376801967621px;
text-align: center;
color: rgba(255, 255, 255, 1);

}
.delivery3HeadingMain{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
}
.delivery3Heading{
    font-family: Changa;
font-size: 56px;
font-weight: 600;
line-height: 40px;
letter-spacing: -0.20000000298023224px;
text-align: center;
color: rgba(255, 255, 255, 1);

}
.delivery3Text{
    font-family: Changa;
    font-size: 24.64px;
    font-weight: 300;
    line-height: 20.02px;
    text-align: right;
    color: rgba(255, 255, 255, 1);

}


.partnersMain{
    padding-top: 60px;
}
.partnersHeadingMain{
    font-family: Changa;
font-size: 32px;
font-weight: 600;
line-height: 58.88px;
text-align: center;
color: rgba(0, 0, 0, 1);

}
.partnersMainImages{
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
}
.partnerImg{
    width: 190px;
}
.accordianMain{
    padding-top: 20px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordiancont{
    margin-bottom: 10px;
    width: 698px;
    box-shadow: -2px 2px 10px 0px rgba(73, 73, 73, 0.2) !important;
    /* padding: 12px 16px 12px 16px; */
    border-radius: 16px ;
    
}

.accordianHeading{
    padding-top: 70px;
    font-family: Changa;
    font-size: 32px;
    font-weight: 600;
    line-height: 58.88px;
    text-align: center;
    
    color: rgba(0, 0, 0, 1);


}
.accordianText{
    text-align: right;
    font-weight: 500;

}
@media (max-width:1268px) {
    .SecondContainerMain{
        gap: 40px;
    }
    .secondrightImg {
        width: 520px;
        height: 460px;
    }
    
}
@media (max-width:1200px) {
    .SecondContainerMain{
        gap: 40px;
        width: 96%;
    }
    .secondrightImg {
        width: 520px;
        height: 460px;
    }
    .secondLeftText{
        width: 100%;
    }
}
@media (max-width:1050px) {
    .partnersMainImages{
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 10px;
        padding-right: 10px;
        gap: 40px;
    }
}

@media (max-width:992px) {
    .SecondContainerMain{
        gap: 40px;
        width: 87%;
        flex-direction: column-reverse;
    }
    .secondrightImg {
        width: 520px;
        height: 460px;
    }
    .secondLeftText{
        width: 100%;
    }
}

@media (max-width:845px) {
    .deliveryContainer {
        padding-top: 190px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
}
.deliveryMain{
    height: 500px;
}
}
@media (max-width:821.5px) {
    .serviceIconsTextMain {
        gap: 60px;
    }

}

@media (max-width:650px){
    .serviceIconsTextMain {
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .serviceHeading{
        padding-bottom: 30px;
    }
    .iconMain {
        padding-bottom: 0px;
        padding-top: 0px;
        padding-left:5px;
        padding-right: 5px;
    }
    .IconDiv{
        width: 80px;
        height: 80px;
    }
    .serIcon1{
        width: 40px;
        height: 40px;
    }
}
@media (max-width:710px){
    .accordiancont{
        margin-bottom: 10px;
        width: 100%;
    
}
}
@media (max-width:625px){
    .homeHeading {
        padding-top: 70px;
        font-size: 36px;
        line-height: 64px;
    }
    .homeText{
        font-size: 18px;
        line-height: 30px;
    }
    .homeBtn {
        width: 124px;
        height: 40px;
        font-size: 15px;
        line-height: 30px;
    }
    .homeButton2Main {
        padding-top: 80px;
        padding-bottom: 40px;
        gap: 10px;
    }
    .homeBtn1 {
        gap: 10px;
        width: 180px;
        height: 80px;
        font-size: 14px;
        line-height: 26px;
    }
    .btnicon1{
width: 38px;
    }
 
    .btnTextInner {
        width: 80%;
    }
    .btnTextInner2 {
        width: 80%;
    }
    .btnicon2{
width: 50px;
    }


    .midSectionMain {
        padding-top: 55px;
    }
    .midSectionText{
        padding-top: 8px;
        width: 90%;
    }
}
@media (max-width:535px){
    .secondrightImg{
        width: 420px;
        height: 380px;
    }
}
@media (max-width:485px){
    .deliveryContainer {
        padding-top: 100px;
    }
    .delivery1Heading{
        font-size: 28px;
    }
    .delivery1Text{
        font-size: 16px;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    }
    .delivery2Heading{
        font-size: 90px;
        line-height: 80px;
    }
    .delivery3Heading{
        font-family: Changa;
        font-size: 45px;
        line-height: 32px;
    }
    .delivery3Text{
        font-size: 20px;
        line-height: 18px;
    }
    
    .deliveryMain{
        height: 380px;
    }
}
@media (max-width:465px){
    .partnerImg{
width: 150px;
    }
    .partnersHeadingMain {
        font-size: 28px;
        line-height: 54px;
    }
    .accordianHeading {
        padding-top: 50px;
        font-size: 28px;
        line-height: 54px;
    }
    .accordianMain {
        padding-top: 20px;
        padding-bottom: 30px;
    }

}

@media (max-width:425px){
    .homeHeading {
        padding-top: 40px;
        font-size: 32px;
        line-height: 60px;
    }
    .homeText{
        font-size: 16px;
        line-height: 28px;
    }
    .homeBtn {
        width: 110px;
        height: 36px;
        font-size: 14px;
        line-height: 28px;
    }
    .homeButton2Main {
        padding-top: 50px;
        padding-bottom: 30px;
        gap: 10px;
    }
    .homeBtn1 {
        gap: 10px;
        width: 150px;
        height: 68px;
        font-size: 12px;
        line-height: 21px;
    }
    .btnicon1{
width: 32px;
    }
 
    .btnTextInner {
        width: 80%;
    }
    .btnTextInner2 {
        width: 85%;
    }
    .btnicon2{
width: 40px;
    }


    .SecondContainerMain{
        padding-top: 50px;
        gap: 30px;
    }
    .secondrightImg{
        width: 310px;
        height: 280px;
    }
    .secondLeftHeading{
        font-size: 24px;
        line-height: 48px;
    }
    .secondLeftText{
        font-size: 15px;
        line-height: 30px;
    }
    .secondleftsideBtn{
        width: 230px;
        height: 42px;
        font-size: 14px;
    }



    .midSectionMain {
        padding-top: 55px;
    }
    .midSectionHeading {
        font-size: 24px;
        line-height: 54px;
    }
    .midSectionText{
        width: 90%;
        font-size: 15px;
    }


    .serviceHeading {
        font-size: 24px;
        line-height: 54px;
    }
}