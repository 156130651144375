.navbtn{
    width: 134px;
    height: 41px;
    border-radius: 9px;
    border: none;
    background: rgba(4, 14, 65, 1);
    font-family: Changa;
    font-size: 16px;
    font-weight: 400;
    line-height: 29.44px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}

@media (max-width:425px) {

    .navbtn {
    width: 100px;
    height: 38px;
    font-size: 14px;
    line-height: 24px;
}
}