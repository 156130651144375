.footerMain{
    background-color: rgba(247, 247, 247, 1);

}
.footerContainer{
    width: 100%;
    padding-top: 70px;
    display: flex;
    justify-content: space-around;
}

.Section1Heading{
    font-family: Changa;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.30000001192092896px;
text-align: right;
color: rgba(122, 126, 135, 1);

}
.Section1InputMain{
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 5px;

}
.section1Input{
    padding-left: 16px;
     border-radius: 8px;
    width: 272px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(179, 189, 199, 1);
    font-family: Changa;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.30000001192092896px;
text-align: left;
color: rgba(146, 152, 159, 1);

}
.section1Btn{
    width: 120px;
height: 40px;
background-color: rgba(217, 42, 37, 1);
border: 0;
width: 120px;
height: 40px;
border-radius: 8px;
font-family: Changa;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.30000001192092896px;
text-align: center;
color: rgba(255, 255, 255, 1);

}



.section2Heading{
    font-family: Changa;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: right;
    color: rgba(108, 113, 123, 1);

}
.section2Text{
    padding-top: 20px;
    font-family: Changa;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: right;
color: rgba(108, 113, 123, 1);
width: 80%;
margin-left: auto;
}
.section2Heading2{
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: Changa;
font-size: 16px;
font-weight: 700;
line-height: 22px;
text-align: right;
color: rgba(108, 113, 123, 1);

}
.section2Text2{
    font-family: Changa;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: right;
color: rgba(50, 50, 50, 1);

}
.section2IconMain{
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.sectionIcon1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44.33px;
height: 44px;
border-radius: 100px;
background: rgba(255, 255, 255, 1);

}

.footerSection3Main{
    width: 50%;
    max-width: 343px;
}
.section3Logo{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
.footerlogo{
    width: 239px;
/* height: 136px; */


}
.section3Text{
    font-family: Changa;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: right;
color: rgba(108, 113, 123, 1);

}
.footerline{
    margin-top: 50px;
    width: 100%;
    border: 1px solid rgba(222, 222, 222, 1);
}
.footerLastMain{
    padding-top: 30px;
    padding-bottom: 30px;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}
.rightsideMain{
    display: flex;
    gap: 20px;

}
.leftText{
    font-family: Almarai;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(108, 113, 123, 1);

}
.leftText span{
    font-family: Almarai;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(108, 113, 123, 1);

}
.rightText1{
    font-family: Almarai;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: center;
color: rgba(108, 117, 125, 1);


}

@media (max-width:1150px){
    .footerContainer{
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        padding-top: 70px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .footerSection3Main {
        width: 100%;
        max-width: max-content;
        
    }
    .section3Logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .section3Text{
      width: 75%;
      margin-left: auto;
      margin-right: auto;
        text-align: center;
    }
    .footerSection2Main{
        padding-top: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .section2Text{
        padding-top: 20px;
        text-align: center;
        width: 100%;
        margin-left: 0;
    }
    .footerSection1Main{
        padding-top: 60px;

    }
    .Section1Heading{
        text-align: center;
    }
    .Section1InputMain {
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
}

@media (max-width:700px){
    .footerLastMain {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 96%;
    }
    .leftText{
        font-size: 12px;
        line-height: 22px;
    }
    .leftText span{
        font-size: 12px;
        line-height: 22px;
    }
    .rightText1{
        font-size: 12px;
        line-height: 22px;
    }
   .rightsideMain {
        gap: 15px;
    }
}
@media (max-width:500px){
    .footerContainer {
        padding-top: 40px;
    }
    .footerlogo{
        width: 160px;
    }
    .section3Text{
        width: 96%;
    }

    .footerSection2Main {
        padding-top: 40px;
    }
    .footerSection1Main {
        padding-top: 40px;
    }
    .Section1InputMain{
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }





    .footerLastMain {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 97%;
    }
    .leftText{
        font-size: 11px;
        line-height: 22px;
        text-align: center;
    }
    .leftText span{
        font-size: 11px;
        line-height: 22px;
        text-align: center;
    }
    .rightText1{
        font-size: 11px;
        line-height: 22px;
        text-align: center;
    }
   .rightsideMain {
        gap: 10px;
    }
}
